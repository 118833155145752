import { Alert } from "antd";
import { useFormatMessage } from "hooks";
import React from "react";
import ManageReservationAlertProps from "./ManageReservationAlertProps";

export const ReservationCancelledAlert: React.FC<ManageReservationAlertProps> = (
    props: ManageReservationAlertProps
) => {
    const { emailAddress, reservationCode } = props;
    return (
        <Alert
            type="success"
            message={useFormatMessage("Reservation.cancelled.alert.title", { reservationCode })}
            description={useFormatMessage("Reservation.cancelled.alert.subtitle", { emailAddress })}
        />
    );
};
