import React, { useState } from "react";
import { Card, Typography, Space, Radio, Button, Input } from "@iqmetrix/antd";
import { ReservationStatus } from "shared";
import "shared/styles/reservation-styles.scss";
import { useFormatMessage } from "hooks";

const { Paragraph } = Typography;
const { TextArea } = Input;

interface ManageReservationCardProps {
    status: string;
    isReadyForPickup: boolean | null;
    cancelNotes: string;
    onManageReservation: (e: any) => void;
    onChangeReadyForPickup: (e: any) => void;
    onChangeCancelNotes: (e: any) => void;
}

export const ManageReservationCard: React.FC<ManageReservationCardProps> = (props: ManageReservationCardProps) => {
    const { status, isReadyForPickup, cancelNotes, onManageReservation, onChangeReadyForPickup, onChangeCancelNotes } =
        props;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    let render;

    const reservationReady = useFormatMessage("Reservation.ready");
    const reservationCancelled = useFormatMessage("Reservation.cancelled");

    switch (status) {
        case ReservationStatus.NEW:
            render = (
                <Card
                    title="Manage the order"
                    className="manage-card"
                    actions={[
                        <Button
                            type="primary"
                            id="submit-button"
                            loading={isSubmitting}
                            disabled={isSubmitting}
                            onClick={(e) => {
                                setIsSubmitting(true);
                                onManageReservation(e);
                            }}
                        >
                            Submit
                        </Button>,
                    ]}
                >
                    <Space direction="vertical">
                        <Radio.Group onChange={onChangeReadyForPickup} value={isReadyForPickup}>
                            <Radio value={true}>Order is ready for pickup</Radio>
                            <Radio value={false}>Cancel the order</Radio>
                        </Radio.Group>
                        {isReadyForPickup === false ? (
                            <div className="textarea-block">
                                <TextArea
                                    showCount
                                    autoSize
                                    maxLength={200}
                                    placeholder="Cancellation Reason"
                                    onChange={onChangeCancelNotes}
                                    value={cancelNotes}
                                />
                                <div className="label">*This message will be sent to the customer</div>
                            </div>
                        ) : null}
                        <hr />
                    </Space>
                </Card>
            );
            break;
        case ReservationStatus.READY_FOR_PICKUP:
            render = (
                <Card title="Manage the order" className="manage-card">
                    <Space direction="vertical">
                        <Paragraph>{reservationReady}</Paragraph>
                    </Space>
                </Card>
            );
            break;
        case ReservationStatus.CANCELLED:
            render = (
                <Card title="Manage the order" className="manage-card">
                    <Space direction="vertical">
                        <Paragraph>{reservationCancelled}</Paragraph>
                    </Space>
                </Card>
            );
            break;
        default:
            render = null;
            break;
    }

    return render;
};
