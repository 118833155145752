import "@iqmetrix/antd/dist/antd.css";

import * as Sentry from "@sentry/react";

import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";

import { ErrorBoundary, Profiler, I18nProvider } from "./components";
import { AuthProvider } from "./providers";
import { BrowserRouter as Router } from "react-router-dom";

import { App } from "./App";
import { getEnvironment } from "shared";

import "index.scss";
import { TrackerId } from "shared/analytics";
import * as packageJson from "../package.json";

async function init() {
    const env = getEnvironment();

    if (process.env.NODE_ENV !== "development") {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            environment: env,
            attachStacktrace: true,
            release: `${packageJson.name}@${packageJson.version}`,
            ignoreErrors: [
                // Ignore errors that are unactionable. These are due to the user's network.
                "TypeError: Failed to fetch",
                "TypeError: NetworkError when attempting to fetch resource.",
                "TypeError: Cancelled",
            ],
        });

        ReactGA.initialize(TrackerId);
        ReactGA.set({ env: env });
    }

    const Application = () => (
        <Profiler>
            <ErrorBoundary>
                <I18nProvider>
                    <Router>
                        <AuthProvider>
                            <App />
                        </AuthProvider>
                    </Router>
                </I18nProvider>
            </ErrorBoundary>
        </Profiler>
    );

    ReactDOM.render(<Application />, document.getElementById("root"));
}

init();
