import { getLoginEndpoint, getLogoutEndpoint, getMeEndpoint } from "../../constants";
import { authenticatedFetch } from "shared/authenticated-fetch";
import { AuthUser } from "models";

let token: string | undefined | null;
const tokenName = "oauthToken";
const redirectUrl = `${window.location.protocol}//${window.location.host}/oauth`;

interface QueryString {
    access_token?: string;
    state?: string;
    token_type?: string;
    expires_in?: string;
    [key: string]: string | undefined;
}

export const authenticate = async (): Promise<AuthUser> => {
    token = getAccessToken();
    if (token && token !== "null") {
        try {
            setAccessToken(token);
            const authUser: AuthUser = await getAuthUser();
            return authUser;
        } catch (error) {
            fetchAccessToken(); // token has expired, get new token
            throw new Error(error as string);
        }
    } else {
        throw new Error("User is not logged in");
    }
};

export const fetchAccessToken = () => {
    window.location.href = getLoginUrl();
};

export const clearTokenFromStorage = () => {
    window.localStorage.removeItem(tokenName);
    window.location.href = `${getLogoutEndpoint()}?accessToken=${token}&returnUrl=${encodeURIComponent(redirectUrl)}`;
};

export const getAccessToken = () => {
    let token;
    token = getAccessTokenFromUrl();
    if (!token) {
        token = getAccessTokenFromStorage();
    }
    return token;
};

const getLoginUrl = () => {
    const params = [
        { key: "response_type", value: "token" },
        { key: "client_id", value: "storefront-orders" },
        { key: "redirect_uri", value: redirectUrl },
    ].map((param) => param.key + "=" + param.value);
    return `${getLoginEndpoint()}?${params.join("&")}`;
};

const getAuthUser = async (): Promise<AuthUser> => {
    const response = await authenticatedFetch(getMeEndpoint());
    if (!response.ok) throw response;
    const results: AuthUser = await response.json();
    return results;
};

const getAccessTokenFromUrl = () => {
    return getQueryStrings(window.location.hash, "access_token");
};

const getAccessTokenFromStorage = (): string | null => {
    return window?.localStorage?.getItem(tokenName);
};

const setAccessToken = (token: string) => {
    window.localStorage.setItem(tokenName, token);
};

const getQueryStrings = (hash: string, key: string): string | undefined => {
    const queryStrings: QueryString = hash
        .replace("#", "")
        .split("&")
        .reduce((prev: QueryString, curr: string) => {
            const [key, value] = curr.split("=");
            return {
                ...prev,
                [key]: value,
            };
        }, {});
    return queryStrings[key];
};
