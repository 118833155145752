import React from "react";
import { Skeleton, Layout, Space, Card, Row, Col, Typography } from "@iqmetrix/antd";
const { Title } = Typography;

export const ReservationPageSkeleton: React.FC = () => {
    return (
        <Space direction="vertical" size="middle">
            <Layout className="content-container">
                <Card>
                    <Skeleton title paragraph={{ rows: 0 }} active />
                    <br />
                    <Row justify="space-around" gutter={[16, 24]}>
                        <Col xl={8} md={12} sm={24}>
                            <div className="d-flex justify-center">
                                <Skeleton.Image />
                            </div>
                        </Col>
                        <Col xl={8} md={12} sm={24}>
                            <Space direction="vertical">
                                <Title level={4}>Order information</Title>
                                <Skeleton
                                    paragraph={{ rows: 6, width: ["50%", "50%", "50%", "50%", "50%", "50%"] }}
                                    title={false}
                                    active
                                />
                                <Title level={4}>Customer Information</Title>
                                <Skeleton paragraph={{ rows: 3, width: ["50%", "50%", "50%"] }} title={false} active />
                            </Space>
                        </Col>
                        <Col xl={8} md={12}>
                            <Card
                                title="Manage the order"
                                className="manage-card"
                                actions={[<Skeleton.Button active />]}
                            >
                                <Skeleton paragraph={{ rows: 2, width: ["50%", "50%"] }} title={false} active />
                                <hr />
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </Layout>
        </Space>
    );
};
