import React, { useContext } from "react";
import { Layout, Affix, Button, Typography, Menu, Dropdown, CaretDownFilled } from "@iqmetrix/antd";
import { SignIn, SignInProps } from "@iqmetrix/antd/dist/components/signin";
import { Link } from "react-router-dom";
import { useFormatMessage } from "hooks";
import { authStore } from "providers";

const { Header } = Layout;
const { Title } = Typography;

export const AppHeader: React.FC = () => {
    const {
        logout,
        state: { isAuthenticated, user },
    } = useContext(authStore);

    const logOut = useFormatMessage("Drawer.log.out");
    const appName = useFormatMessage("appName");

    const DropdownMenu = () => {
        return (
            <Dropdown key="more" overlay={menu} trigger={["click"]}>
                <Button type="text" size="small" className="user-dropdown">
                    {user.FirstName} {user.LastName} <CaretDownFilled />
                </Button>
            </Dropdown>
        );
    };

    const menu = (
        <Menu>
            <Menu.Item>
                <Link
                    to="/"
                    onClick={() => {
                        logout();
                    }}
                >
                    {logOut}
                </Link>
            </Menu.Item>
        </Menu>
    );

    const signInProps: SignInProps = {
        visible: true,
        items: [
            {
                text: logOut,
                onclick: () => logout(),
                hidden: !isAuthenticated,
                className: "signout-link",
            },
        ],
    };

    return (
        <Affix>
            <Header className="site-page-header">
                <Title level={3} className="app-header">
                    <span>{appName}</span>
                </Title>
                {isAuthenticated && (
                    <div>
                        <div className="right-menu-item">
                            <DropdownMenu key="app-user" />
                        </div>
                        <div className="mobile-right-menu-item">
                            <SignIn visible={signInProps.visible} items={signInProps.items} />
                        </div>
                    </div>
                )}
            </Header>
        </Affix>
    );
};
