import React, { useEffect, useState } from "react";
import { Card, Typography, Layout, Space, Row, Col, Alert } from "@iqmetrix/antd";
import { useParams } from "react-router-dom";
import { formatCurrency, formatPhoneNumber, getReservationDetails, patchReservation, ReservationStatus } from "shared";
import { ReservationDetails } from "models";
import "shared/styles/reservation-styles.scss";
import { Carousel, Image } from "antd";
import {
    ManageReservationCard,
    ManageReservationErrorAlert,
    ReservationCancelledAlert,
    ReservationReadyAlert,
} from "components";
import fallbackImage from "../../assets/fallback.png";
import { ReservationPageSkeleton } from "components/reservation-page-skeleton";

const { Title, Text } = Typography;

export const ReservationPage: React.FC = () => {
    const { companyId, locationId, reservationId } = useParams() as {
        companyId: any;
        locationId: any;
        reservationId: any;
    };

    const [reservationDetails, setReservationDetails] = useState<ReservationDetails | null>();
    const [isReadyForPickup, setIsReadyForPickup] = useState<boolean | null>(null);
    const [cancelNotes, setCancelNotes] = useState<string>("");
    const [isReservationCancelled, setIsReservationCancelled] = useState<boolean>(false);
    const [isReservationReady, setIsReservationReady] = useState<boolean>(false);
    const [anErrorOccurred, setAnErrorOccurred] = useState<{ errorMessage: string } | null>(null);
    const [manageCardKey, setManageCardKey] = useState<number>(0);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            try {
                setIsLoading(true);
                setLoadingErrorMessage(null);
                const reservationDetails = await getReservationDetails(companyId, locationId, reservationId);

                if (mounted) {
                    setReservationDetails(reservationDetails);
                }
            } catch (error) {
                if ((error as Response)?.status === 404)
                    setLoadingErrorMessage(`Could not find the reservation information for id: ${reservationId}`);
                else setLoadingErrorMessage("An unknown error occurred.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();

        return () => {
            mounted = false;
        };
    }, [companyId, locationId, reservationId]);

    const onManageReservation = async (reservationDetails: ReservationDetails) => {
        try {
            if (reservationDetails == null) throw new Error("Unexpected error: reservation details cannot be empty");
            const status = isReadyForPickup ? ReservationStatus.READY_FOR_PICKUP : ReservationStatus.CANCELLED;
            const notes = cancelNotes;

            const updatedReservationDetails = {
                ...reservationDetails,
                reservation: { ...reservationDetails.reservation, status, notes },
            };

            const result = await patchReservation(updatedReservationDetails.reservation);

            if (result) {
                setReservationDetails(updatedReservationDetails);
                setIsReservationCancelled(status === ReservationStatus.CANCELLED);
                setIsReservationReady(status === ReservationStatus.READY_FOR_PICKUP);
            }
        } catch (e) {
            setAnErrorOccurred({ errorMessage: (e as Error).stack as string });
        } finally {
            setManageCardKey(manageCardKey + 1);
        }
    };
    if (isLoading) {
        return <ReservationPageSkeleton />;
    } else if (reservationDetails) {
        const { reservation, products, pickupLocation } = reservationDetails as ReservationDetails;
        return (
            <Space direction="vertical" size="middle">
                <Layout className="content-container">
                    {isReservationCancelled ? (
                        <ReservationCancelledAlert
                            reservationCode={reservation.reservationCode}
                            emailAddress={reservation.customer.email}
                        />
                    ) : null}
                    {isReservationReady ? (
                        <ReservationReadyAlert
                            reservationCode={reservation.reservationCode}
                            emailAddress={reservation.customer.email}
                        />
                    ) : null}
                    {anErrorOccurred ? (
                        <ManageReservationErrorAlert
                            errorMessage={anErrorOccurred.errorMessage}
                            afterClose={() => setAnErrorOccurred(null)}
                        />
                    ) : null}
                    {products.map((product, productIndex) => (
                        <Card key={productIndex}>
                            <Title level={2}>{`${product.name} - ${reservation.reservationCode}`}</Title>
                            <br />
                            <Row gutter={[16, 24]} justify="space-around">
                                <Col xl={8} md={12} sm={24} className="d-flex justify-center">
                                    <Carousel>
                                        {product.assets.map((asset, assetIndex) => (
                                            <Image
                                                preview={false}
                                                key={assetIndex}
                                                width={200}
                                                src={asset}
                                                fallback={fallbackImage}
                                            />
                                        ))}
                                    </Carousel>
                                </Col>
                                <Col xl={8} md={12} sm={24}>
                                    <Space direction="vertical">
                                        <Title level={4}>Order information</Title>
                                        <Text strong>{product.name}</Text>
                                        {Object.entries(product.options).map((x, index) => (
                                            <div key={index}>
                                                <Text>{x[0]}: </Text>
                                                <Text strong>{x[1] as string}</Text>
                                            </div>
                                        ))}
                                        <div>
                                            <Text>Quantity: </Text>
                                            <Text strong>{product.quantity}</Text>
                                        </div>
                                        <div>
                                            <Text>Full retail price: </Text>
                                            <Text strong>{formatCurrency(product.price)}</Text>
                                        </div>
                                        <div>
                                            <Text>Pickup location: </Text>
                                            <Text strong>{pickupLocation.address}</Text>
                                        </div>
                                        <br />
                                        <Title level={4}>Customer Information</Title>

                                        <div>
                                            <Text>Name: </Text>
                                            <Text
                                                strong
                                            >{`${reservation.customer.firstName} ${reservation.customer.lastName}`}</Text>
                                        </div>
                                        <div>
                                            <Text>Email: </Text>
                                            <Text strong>{reservation.customer.email}</Text>
                                        </div>
                                        <div>
                                            <Text>Phone: </Text>
                                            <Text strong>{formatPhoneNumber(reservation.customer.phoneNumber)}</Text>
                                        </div>
                                    </Space>
                                </Col>
                                <Col xl={8} md={12} sm={24}>
                                    <ManageReservationCard
                                        key={manageCardKey}
                                        status={reservation.status}
                                        cancelNotes={cancelNotes}
                                        isReadyForPickup={isReadyForPickup}
                                        onManageReservation={() => onManageReservation(reservationDetails)}
                                        onChangeReadyForPickup={(e) => setIsReadyForPickup(e.target.value)}
                                        onChangeCancelNotes={(e) => setCancelNotes(e.target.value)}
                                    ></ManageReservationCard>
                                </Col>
                            </Row>
                        </Card>
                    ))}
                </Layout>
            </Space>
        );
    } else
        return (
            <>
                {loadingErrorMessage ? (
                    <Alert type="error" message="An error occurred" description={loadingErrorMessage} />
                ) : null}
            </>
        );
};
