// *******************************************************************
// Auth provider provides the application with every functionality
// related to the authentication; login and logout
// *******************************************************************
import React, { useEffect, useReducer, createContext, ReactChild } from "react";
import * as Sentry from "@sentry/react";
import { fetchAccessToken, authenticate, clearTokenFromStorage } from "shared/client";
import { AuthUser } from "models";
import { reducer } from "./reducer";
import { useHistory } from "react-router-dom";

export type AuthState = {
    isAuthenticated: boolean;
    user: AuthUser;
    error: Error | null;
    isAuthLoading: boolean;
    redirectPath: string | null;
};

export enum AuthType {
    Logout = "logout",
    Error = "error",
    Success = "success",
    Load = "load",
    SetRedirectPath = "set-redirect-path",
}

type InitialStateType = {
    state: AuthState;
    logout: () => void;
    login: () => void;
    setRedirectPath: (redirectPath: string | null) => void;
};

const initialState: AuthState = {
    isAuthLoading: true,
    isAuthenticated: false,
    user: {} as AuthUser,
    error: null,
    redirectPath: localStorage.getItem("redirectPath") || null,
};

const authStore = createContext<InitialStateType>({
    state: initialState,
    logout: function () {
        // implemented inside the provider
    },
    login: function () {
        // implemented inside the provider
    },
    setRedirectPath: function () {
        // implemented inside the provider
    },
});
const { Provider } = authStore;

const AuthProvider = ({ children }: { children: ReactChild }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const history = useHistory();

    const login = async () => {
        try {
            fetchAccessToken();
        } catch (error) {
            dispatch({ type: AuthType.Error, error: error as Error | null });
        }
    };

    const logout = () => {
        dispatch({ type: AuthType.Load });
        try {
            clearTokenFromStorage();
            dispatch({ type: AuthType.Logout, user: {} as AuthUser });
        } catch (error) {
            dispatch({ type: AuthType.Error, error: error as Error | null });
        }
    };

    const setRedirectPath = (redirectPath: string | null) => {
        dispatch({ type: AuthType.SetRedirectPath, redirectPath });
    };

    useEffect(() => {
        dispatch({ type: AuthType.Load });
        const doFetch = async () => {
            try {
                const authUser: AuthUser = await authenticate();
                const type = Object.keys(authUser).length > 0 ? AuthType.Success : AuthType.Logout;
                dispatch({ type, user: authUser });
                Sentry.setUser({
                    id: authUser?.Id.toString(),
                    email: authUser.Email,
                    username: authUser.UserName,
                });
                Sentry.setTag("parentEntityId", authUser.ParentEntityId.toString());

                if (state.redirectPath != null) {
                    const redirectPath = state.redirectPath;
                    dispatch({ type: AuthType.SetRedirectPath, redirectPath: null });
                    history.replace(redirectPath);
                }
            } catch (error) {
                dispatch({ type: AuthType.Logout, user: {} as AuthUser });
            }
        };
        doFetch();
    }, [state.redirectPath, history]);

    return <Provider value={{ state, logout, login, setRedirectPath }}>{children}</Provider>;
};
export { authStore, AuthProvider };
