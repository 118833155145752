import React, { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { authStore } from "providers";

interface PrivateRouteProps extends RouteProps {
    component: React.FC<any>;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
    const { path, component: Component, location } = props;

    const {
        state: { isAuthenticated, isAuthLoading },
        setRedirectPath,
    } = useContext(authStore);

    const redirectToLogin = !isAuthenticated && !isAuthLoading;

    if (redirectToLogin) {
        setRedirectPath(location?.pathname || null);
    }

    return (
        <Route
            path={path}
            render={(routeProps: RouteProps) =>
                redirectToLogin ? <Redirect to="/login" /> : <Component {...routeProps}></Component>
            }
        />
    );
};
