// The settings are applied in the index.tsx and swapped in the release pipeline
interface Config {
    appName: string;
    environment: "local" | "int" | "rc" | "prod";
    authEnvironmentSuffix: "int" | "rc" | "";
    storeBackUrl: string;
    supportedLanguages: string[];
}

const defaultConfig: Config = {
    appName: "StorefrontOrders",
    environment: "local",
    authEnvironmentSuffix: "int",
    storeBackUrl: "https://storebackint.azure.development.k8s.iqmetrix.net",
    supportedLanguages: ["en"],
};

export const Configuration: Config = {
    ...defaultConfig,
    ...(window as any).STOREFRONT_ORDERS_CONFIG,
};
