import { Alert } from "antd";
import { useFormatMessage } from "hooks";
import React from "react";
export const ManageReservationErrorAlert: React.FC<{ errorMessage: string; afterClose: () => void }> = (props: {
    errorMessage: string;
    afterClose: () => void;
}) => {
    return (
        <Alert
            type="error"
            closable
            afterClose={props.afterClose}
            message={useFormatMessage("Manage.Reservation.Error")}
            description={props.errorMessage}
        />
    );
};
