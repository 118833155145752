import { Configuration } from "./config";

const IQAccountsAPIRoot = `https://accounts${Configuration.authEnvironmentSuffix}.iqmetrix.net`;

export const getReservationEndpoint = (companyId: number, locationId: number, reservationId: string) => {
    return `${Configuration.storeBackUrl}/v1/companies/${companyId}/locations/${locationId}/reservations/${reservationId}/details`;
};

export const patchReservationEndpoint = (companyId: number, locationId: number, reservationId: string) => {
    return `${Configuration.storeBackUrl}/v1/companies/${companyId}/locations/${locationId}/reservations/${reservationId}`;
};

export const getLogoutEndpoint = () => {
    return `${IQAccountsAPIRoot}/logout`;
};

export const getLoginEndpoint = () => {
    return `${IQAccountsAPIRoot}/v1/oauth2/auth`;
};

export const getMeEndpoint = () => {
    return `${IQAccountsAPIRoot}/v1/me`;
};
