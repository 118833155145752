import React, { useContext } from "react";
import { withGARouter } from "@iqmetrix/analytics";
import { Switch, Route } from "react-router-dom";
import ReactGA from "react-ga";
import { Layout, Row, Spin, Alert } from "@iqmetrix/antd";
import { Landing, ReservationPage } from "pages";
import { AppHeader, PrivateRoute } from "components";
import { authStore } from "providers";

const { Content } = Layout;

const PageNotFound: React.FC = () => (
    <Alert type="error" message="404 - Not Found" description="This page does not exist" />
);

export const App: React.FC = () => {
    const {
        state: { user, isAuthenticated, isAuthLoading },
    } = useContext(authStore);

    // Add your app's routes below
    // Routing Docs: https://reacttraining.com/react-router/web/guides/quick-start
    // Routing Standards: https://design-system.iqmetrix.net/docs/web-dev-docs/guides/routing-standards

    if (process.env.NODE_ENV !== "development" && isAuthenticated && user) {
        ReactGA.set({ userId: user.Id });
    }

    return (
        <Layout>
            <AppHeader></AppHeader>
            {isAuthLoading ? (
                <Row justify="space-around" align="middle" className="center-page-content">
                    <Spin size="large" />
                </Row>
            ) : (
                <Content>
                    <Switch>
                        <PrivateRoute exact path="/" component={PageNotFound}></PrivateRoute>
                        <PrivateRoute
                            exact
                            path="/companies/:companyId/locations/:locationId/reservations/:reservationId/details"
                            component={withGARouter(ReservationPage)}
                        />
                        <Route exact path="/login" component={withGARouter(Landing)} />
                        <Route exact path="/oauth">
                            <h1>Redirecting...</h1>
                        </Route>
                        <Route path="*">
                            <PageNotFound />
                        </Route>
                    </Switch>
                </Content>
            )}
        </Layout>
    );
};
