import * as React from "react";

import { Result } from "@iqmetrix/antd";
import * as Sentry from "@sentry/react";
import { FallbackRender } from "@sentry/react/dist/errorboundary";

const Fallback: FallbackRender = ({ error, resetError }) => {
    resetError();
    return <Result icon={null} response={error.message} />;
};

interface ErrorBoundaryProps {
    children: any;
}

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => (
    <Sentry.ErrorBoundary fallback={Fallback}>{children}</Sentry.ErrorBoundary>
);
