const messages = {
    appName: "Manage order",
    "Drawer.log.out": "Log out",
    "Landing.page": "Manage order",
    "Landing.page.button": "LOGIN",
    "Reservation.ready": "The customer has been notified that the order is ready for pickup.",
    "Reservation.cancelled": "The customer has been notified that the order is cancelled.",
    "Reservation.cancelled.alert.title": "Reservation #{reservationCode} has been cancelled",
    "Reservation.cancelled.alert.subtitle": "A cancellation email has been sent to {emailAddress}.",
    "Reservation.ready.alert.title": "Reservation #{reservationCode} has been confirmed",
    "Reservation.ready.alert.subtitle":
        "An email has been sent to {emailAddress} letting them know that the order is ready.",
    "Manage.Reservation.Error": "An error occurred. Please try again",
    "OrderInformation.Title": "Order information",
};

export default messages;
