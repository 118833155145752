import { AuthState, AuthType } from "./AuthProvider";
import { AuthUser } from "models";

type Action =
    | { type: "load" }
    | { type: "success"; user: AuthUser }
    | { type: "logout"; user: AuthUser }
    | { type: "select location" }
    | { type: "error"; error: Error | null }
    | { type: AuthType.SetRedirectPath; redirectPath: string | null };

export function reducer(state: AuthState, action: Action): AuthState {
    switch (action.type) {
        case AuthType.Error:
            return {
                ...state,
                isAuthLoading: false,
                error: action.error ? action.error : null,
            };
        case AuthType.Success:
            return {
                ...state,
                isAuthLoading: false,
                error: null,
                isAuthenticated: true,
                user: action.user,
            };
        case AuthType.Load:
            return { ...state, isAuthLoading: true };
        case AuthType.Logout:
            return {
                ...state,
                isAuthLoading: false,
                error: null,
                isAuthenticated: false,
                user: {} as AuthUser,
            };
        case AuthType.SetRedirectPath:
            if (action.redirectPath != null) {
                localStorage.setItem("redirectPath", action.redirectPath);
            } else {
                localStorage.removeItem("redirectPath");
            }
            return { ...state, redirectPath: action.redirectPath };
        default:
            throw new Error("Invalid action");
    }
}
