import { Reservation, ReservationDetails } from "models";
import { authenticatedFetch } from "../authenticated-fetch";
import { getReservationEndpoint, patchReservationEndpoint } from "../../constants/endpoints";

export const getReservationDetails = async (
    companyId: number,
    locationId: number,
    reservationId: string
): Promise<ReservationDetails> => {
    const response = await authenticatedFetch(getReservationEndpoint(companyId, locationId, reservationId));
    if (!response.ok) throw response;
    const reservationDetails = await response.json();
    return reservationDetails;
};

export const patchReservation = async (reservation: Reservation): Promise<boolean> => {
    const  { companyId, locationId, id: reservationId ,
    } = reservation;
    const response = await authenticatedFetch(patchReservationEndpoint(companyId, locationId, reservationId), {
        method: "PATCH",
        body: JSON.stringify(reservation),
    });
    if (!response.ok) throw response;
    return true;
};